@if (options$ | async; as locations) {
  <div class="flex flex-col">
    <label>{{ soldToTitle }}</label>
    <p-dropdown
      styleClass="flex items-center w-full"
      [options]="locations"
      [formControl]="location"
      optionLabel="name"
      optionValue="value"
      data-cy="sold-to-options"
    >
    </p-dropdown>
  </div>
}
