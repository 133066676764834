<ev-modal-dialog
  class="min-w-[300px]"
  [$title]="$title()"
  [$subtitle]="$subtitle()"
  [$showBrandLogo]="true"
  (closeDialog)="onClose('closeDialog')"
>
  <div [formGroup]="fg" class="flex flex-row flex-wrap gap-2 justify-center">
    @if (!$origin()) {
      <!-- Todo: need to adjust global height for p-dropdown matching w/ p-button -->
      <p-dropdown
        styleClass="min-w-[250px] h-[37px]"
        [options]="productGroupOptions"
        [placeholder]="productGroupLabel"
        formControlName="selectedProductGroupLink"
        formElement="p-dropdown"
        optionValue="link"
        optionLabel="label"
      ></p-dropdown>
    }
    <p-button
      styleClass="justify-center"
      size="small"
      [disabled]="!fg.controls.selectedProductGroupLink.value"
      (onClick)="onOpenProductGroupRegisterPage()"
      ><span i18n>Register Now</span>
    </p-button>
    <p-button
      styleClass="ev-secondary-button justify-center"
      size="small"
      (onClick)="onClose('continueAsGuest')"
      data-cy="continue-as-a-guest-button"
      ><span i18n>Continue as a guest</span>
    </p-button>
  </div>

  <p-divider></p-divider>

  <div class="flex justify-center gap-2 mb-8">
    <span i18n>Already have an account?</span
    ><a class="cursor-pointer" (click)="onLogin()" i18n>Login</a></div
  >
</ev-modal-dialog>
